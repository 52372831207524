import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";
export const RubberTableHeaders = [
  { text: "日付", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "生ゴム", value: "rawRubber" },
  { text: "Wゴム", value: "wRubber" },
  { text: "Wゴム(二度練り)", value: "wRubberSecond" },
  { text: "色種類", value: "colorType" },
  { text: "色ゴム", value: "colorRubber" },
  { text: "絶縁ゴム", value: "isolateRubber" },
  { text: "絶縁ゴム(二度練り)", value: "isolateRubberSecond" },
  { text: "EM(アロイ)", value: "emAlloy" },
  { text: "出来高数(本数)", value: "volume" },
  { text: "質量(kg)", value: "mass" },
  { text: "製品異常処置報告", value: "abnormality" }
] as const;

export class RubberTable {
  workDate: string;
  updateTs: string | null;
  rawRubber: boolean;
  wRubber: boolean;
  wRubberSecond: boolean;
  colorType: string | null;
  colorRubber: boolean;
  isolateRubber: boolean;
  isolateRubberSecond: boolean;
  emAlloy: boolean;
  volume: number | null;
  mass: number | null;
  abnormality: string | null;

  constructor(props: {
    workDate: string;
    rawRubber: string | null;
    wRubber: string | null;
    wRubberSecond: string | null;
    colorType: string | null;
    colorRubber: string | null;
    isolateRubber: string | null;
    isolateRubberSecond: string | null;
    emAlloy: string | null;
    volume: number | null;
    mass: number | null;
    abnormality: string | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    if (props.rawRubber === "1") {
      this.rawRubber = true;
    } else {
      this.rawRubber = false;
    }
    if (props.wRubber === "1") {
      this.wRubber = true;
    } else {
      this.wRubber = false;
    }
    if (props.wRubberSecond === "1") {
      this.wRubberSecond = true;
    } else {
      this.wRubberSecond = false;
    }
    this.colorType = props.colorType;
    if (props.colorRubber === "1") {
      this.colorRubber = true;
    } else {
      this.colorRubber = false;
    }
    if (props.isolateRubber === "1") {
      this.isolateRubber = true;
    } else {
      this.isolateRubber = false;
    }
    if (props.isolateRubberSecond === "1") {
      this.isolateRubberSecond = true;
    } else {
      this.isolateRubberSecond = false;
    }
    if (props.emAlloy === "1") {
      this.emAlloy = true;
    } else {
      this.emAlloy = false;
    }
    this.volume = props.volume;
    this.mass = props.mass;
    this.abnormality = props.abnormality;
  }
}

export const RubberText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  kneaderOperater: { text: "作業者ニーダー", value: "kneaderOperater" },
  role1Operater: { text: "作業者ロール1号", value: "role1Operater" },
  role2Operater: { text: "作業者ロール2号", value: "role2Operater" },
  rubberOperater: { text: "作業者ゴム帯取作業", value: "rubberOperater" },
  rawRubber: { text: "生ゴム", value: "rawRubber" },
  colorType: { text: "色種類", value: "colorType" },
  wRubber: { text: "Wゴム", value: "wRubber" },
  wRubberSecond: { text: "Wゴム(二度練り)", value: "wRubberSecond" },
  colorRubber: { text: "色ゴム", value: "colorRubber" },
  colorRubberSecond: { text: "色ゴム(二度練り)", value: "colorRubberSecond" },
  isolateRubber: { text: "絶縁ゴム", value: "isolateRubber" },
  isolateRubberSecond: {
    text: "絶縁ゴム(二度練り)",
    value: "isolateRubberSecond"
  },
  emAlloy: { text: "EM(アロイ)", value: "emAlloy" },
  volume: { text: "出来高数(本数)", value: "volume" },
  mass: { text: "質量(kg)", value: "mass" },
  lotNo: { text: "製作ロット番号", value: "lotNo" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Rubber extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  inspectionFlag: string | null;
  kneaderOperater: string | null;
  role1Operater: string | null;
  role2Operater: string | null;
  role3Operater: string | null;
  rubberOperater: string | null;
  rawRubber: string | null;
  colorType: string | null;
  wRubber: string | null;
  wRubberSecond: string | null;
  colorRubber: string | null;
  colorRubberSecond: string | null;
  isolateRubber: string | null;
  isolateRubberSecond: string | null;
  emAlloy: string | null;
  volume: number | null;
  mass: number | null;
  lotNo: string | null;
  abnormality: string | null;
  biko: string | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Rubber);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Rubber | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Rubber) {
      this.inspectionFlag = _props.inspectionFlag;
      this.kneaderOperater = _props.kneaderOperater;
      this.role1Operater = _props.role1Operater;
      this.role2Operater = _props.role2Operater;
      this.role3Operater = _props.role3Operater;
      this.rubberOperater = _props.rubberOperater;
      this.rawRubber = _props.rawRubber;
      this.colorType = _props.colorType;
      this.wRubber = _props.wRubber;
      this.wRubberSecond = _props.wRubberSecond;
      this.colorRubber = _props.colorRubber;
      this.colorRubberSecond = _props.colorRubberSecond;
      this.isolateRubber = _props.isolateRubber;
      this.isolateRubberSecond = _props.isolateRubberSecond;
      this.emAlloy = _props.emAlloy;
      this.volume = _props.volume;
      this.mass = _props.mass;
      this.lotNo = _props.lotNo;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.inspectionFlag = "0";
    this.kneaderOperater = null;
    this.role1Operater = null;
    this.role2Operater = null;
    this.role3Operater = null;
    this.rubberOperater = null;
    this.rawRubber = "0";
    this.colorType = null;
    this.wRubber = "0";
    this.wRubberSecond = "0";
    this.colorRubber = "0";
    this.colorRubberSecond = "0";
    this.isolateRubber = "0";
    this.isolateRubberSecond = "0";
    this.emAlloy = "0";
    this.volume = null;
    this.mass = null;
    this.lotNo = null;
    this.abnormality = null;
    this.biko = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
