
import Vue from "vue";
import moment from "moment";

//class
import { DailyWorkReportKey } from "@/models/dailyWorkReport/DailyWorkReportKey";
import {
  Rubber,
  RubberText,
  RubberTable,
  RubberTableHeaders
} from "@/models/dailyWorkReport/Rubber";
import { PulldownResponse } from "@/models/response/PulldownResponse";
import { CheckboxGroupItems } from "@/components/input/CheckboxGroup.vue";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

// Components
import { Headers } from "@/components/input/DailyWorkReportInput.vue";
import DailyWorkReportFormDialog from "@/components/dialog/DailyWorkReportFormDialog.vue";
import DataIterators from "@/components/table/dailyworkreport/dataiterators/DataIterators.vue";
import Snackbar from "@/components/dialog/Snackbar.vue";
import Overlay from "@/components/dialog/Overlay.vue";
import { ShainSearchRequest } from "@/models/ShainSearchRequest";
import Shain from "@/models/Shain";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";
import { DATE_FORMAT } from "@/defines";

const DATE = moment().format(DATE_FORMAT.DATE);

export default Vue.extend({
  name: "Rubber",
  mixins: [Common, ShowDialogs, Api],
  components: {
    DailyWorkReportFormDialog,
    DataIterators,
    Snackbar,
    Overlay
  },
  props: {
    date: {
      type: String,
      required: false,
      default: DATE
    },
    form: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      selectDate: this.date,
      isAdd: false,
      isOpen: false,
      maxRecord: 30,
      tableHeaders: RubberTableHeaders,
      editRecord: new Rubber(new DailyWorkReportKey({ workDate: DATE })),
      reportData: [] as Rubber[], //テーブルデータ
      title: "ゴム練り作業記録",
      isLoading: false,
      codeGroup: [] as PulldownResponse[],
      userItems: [] as CheckboxGroupItems[] //作業者
    };
  },
  computed: {
    headers() {
      return [
        {
          ...RubberText.workDate,
          type: "date-picker",
          disabled: true
        },
        { ...RubberText.inspectionFlag, type: "checkbox" },
        {
          ...RubberText.kneaderOperater,
          type: "select",
          items: this.userItems,
          required: true
        },
        {
          ...RubberText.role1Operater,
          type: "select",
          items: this.userItems,
          required: true
        },
        {
          ...RubberText.role2Operater,
          type: "select",
          items: this.userItems,
          required: true
        },
        {
          ...RubberText.rubberOperater,
          type: "select",
          items: this.userItems,
          required: true
        },
        { ...RubberText.rawRubber, type: "checkbox" },
        { ...RubberText.colorType, type: "text", maxLength: 20 },
        { ...RubberText.wRubber, type: "checkbox" },
        { ...RubberText.wRubberSecond, type: "checkbox" },
        { ...RubberText.colorRubber, type: "checkbox" },
        { ...RubberText.colorRubberSecond, type: "checkbox" },
        { ...RubberText.isolateRubber, type: "checkbox" },
        { ...RubberText.isolateRubberSecond, type: "checkbox" },
        { ...RubberText.emAlloy, type: "checkbox" },
        { ...RubberText.volume, type: "number", digit: 10, scale: 2 },
        { ...RubberText.mass, type: "number", digit: 10, scale: 2 },
        { ...RubberText.lotNo, type: "text", maxLength: 40 },
        { ...RubberText.abnormality, type: "text", maxLength: 255 },
        { ...RubberText.biko, type: "text", maxLength: 255 }
      ] as Headers[];
    },
    tableRecord() {
      const records: Rubber[] = this.reportData;
      const result = records.map((r: Rubber) => {
        const record = new RubberTable(r);
        const date = moment(r.updateTs).format(DATE_FORMAT.DATE_MINUTE);
        record.updateTs = date;
        return record;
      });
      return result;
    }
  },
  methods: {
    /**
     * 追加
     */
    onAdd() {
      console.log("add");
      this.isAdd = true;
      if (this.reportData.length >= this.maxRecord) {
        this.snackbarText = `${this.maxRecord}列以上は登録できません。`;
        this.snackbar = true;
        return;
      }
      const reocrdKey = new DailyWorkReportKey({ workDate: this.selectDate });
      this.editRecord = new Rubber(reocrdKey);
      this.isOpen = true;
    },
    /**
     * 編集
     * @param value index
     */
    onEdit(value: number) {
      console.log("edit");
      this.isAdd = false;
      this.editRecord = Object.assign({}, this.reportData[value]);
      this.isOpen = true;
    },
    /**
     * コピー
     * @param value DailyWorkReportSearchResponse
     */
    async onCopy(value: DailyWorkReportSearchResponse, close: () => void) {
      console.log("copy", value);
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        const param = new DailyWorkReportKey(value);
        const result = await api.$post<Rubber>(
          api.Paths.dailyWorkReport.rubber.searchKey,
          param
        );
        result.workDate = this.selectDate;
        this.editRecord = result;
        close();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("copy end");
        this.isLoading = false;
      }
    },
    // ==============================
    // 検索
    // ==============================
    async onSearch() {
      console.log("onSearch start");
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        this.reportData = await this.getRubberRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSearch end");
        this.isLoading = false;
      }
    },
    // ==============================
    // 保存
    // ==============================
    async onSave(saveValue: Rubber) {
      console.log("onSave start");
      const showDialogs = new ShowDialogs();
      const api = new Api();
      this.isLoading = true;
      try {
        if (this.isAdd) {
          await api.$post(api.Paths.dailyWorkReport.rubber.insert, saveValue);
        } else {
          await api.$post(api.Paths.dailyWorkReport.rubber.update, saveValue);
        }
        showDialogs.$info("更新しました。", "更新");
        this.reportData = await this.getRubberRecord();
        this.isOpen = false;
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSave end");
        this.isLoading = false;
      }
    },
    /**
     * 削除
     * @param index
     */
    async onDelete(index: number) {
      console.log("onDelete start", index);
      const api = new Api();
      const showDialogs = new ShowDialogs();
      const title = "確認";
      const message = `${index +
        1}列目の作業記録を削除します。\nよろしいですか？`;
      const confirm = await showDialogs.$deleteConfirm(message, title);
      if (!confirm) return;

      this.isLoading = true;
      try {
        const target = this.reportData[index];
        await api.$post(api.Paths.dailyWorkReport.rubber.delete, target);
        showDialogs.$info("削除しました。", "情報");
        this.reportData = await this.getRubberRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onDelete end");
        this.isLoading = false;
      }
    },
    /**
     * ゴム練り作業記録データ取得
     */
    async getRubberRecord() {
      const api = new Api();
      const param = { workDate: new Date(this.selectDate) };
      const result = await api.$post<Rubber[]>(
        api.Paths.dailyWorkReport.rubber.search,
        param
      );
      return result;
    },
    /**
     * データベースから選択項目データ取得
     */
    async getItems() {
      console.log("getItems start");
      const showDialogs = new ShowDialogs();
      const api = new Api();
      this.isLoading = true;

      try {
        //apiパス
        const userPath = api.Paths.shainSearch;
        const codeGroupPath = api.Paths.codeGroupPulldown;

        //リクエスト
        const shainRequest = new ShainSearchRequest({
          department: [this.$store.state.dailyWorkReportStore.nippoType],
          isValid: "1"
        });
        const userPromise = api.$post<Shain[]>(userPath, shainRequest);
        const codeGroupPromise = api.$get<PulldownResponse[]>(codeGroupPath);
        const [user, codeGroup] = await Promise.all([
          userPromise,
          codeGroupPromise
        ]);

        this.codeGroup = codeGroup;
        //ユーザデータ取得
        this.userItems = user.map(e => ({ value: e.userId, text: e.userName }));
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
        this.isLoading = false;
      }
    },
    /**
     * テーブルコード取得
     */
    async getCode() {
      console.log("getCode start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      try {
        //apiパス
        const path = api.Paths.dailyWorkReport.rubber.code;

        //リクエスト
        const code = await api.$get<string>(path);

        this.$store.commit("dailyWorkReportStore/nippoTypeSet", code);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
      }
    }
  },
  async created() {
    await this.getCode();
    await this.getItems();
    await this.onSearch();
  }
});
